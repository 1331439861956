
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import ApiService from "../../../../core/services/ApiService";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const provisional = ref<string | any>("");
    const checks = ref({
      minus: false,
      mayus: false,
      special: false,
      number: false,
      min: false,
    });
    const characters = ref(8);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const regex = ref<any>(
      "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[_.@$!%*?&#])[A-Za-z\\d@$!%*?&_.#]+$"
    );
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string()
        .min(characters.value, "Logitud minima de 8 caracteres")
        .matches(regex.value, "Debe respetar el formato de contraseña")
        .required("La contraseña es necesaria")
        .label("Password"),
      password_confirm: Yup.lazy((value) => {
        return Yup.string()
          .when("password", (password, schema) => {
            return schema.test({
              test: (confirm_password) => confirm_password === password,
              message: "Las contraseñas deben coincidir",
            });
          })
          .label("Confirm Password");
      }),
      provisional: Yup.string().required(
        "La contraseña provisonal es necesaria"
      ),
    });
    let type1 = ref("password");
    let type2 = ref("password");
    let type3 = ref("password");
    const toggleType = (input) => {
      if (input === 1) {
        if (type1.value == "password") {
          type1.value = "text";
        } else {
          type1.value = "password";
        }
      } else if (input === 2) {
        if (type2.value == "password") {
          type2.value = "text";
        } else {
          type2.value = "password";
        }
      } else if (input === 3) {
        if (type3.value == "password") {
          type3.value = "text";
        } else {
          type3.value = "password";
        }
      }
    };
    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      let parms = { ...values, token: route.params.token };
      ApiService.post("api/auth/reset-password/" + route.params.token, parms)
        .then(() => {
          Swal.fire({
            text: "Se ha restablecido tu contraseña!",
            icon: "success",
            buttonsStyling: false,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            router.push("/sign-in");
          }, 2000);
        })
        .catch(({ response }) => {
          // Alert then login failed
          Swal.fire({
            title: "¡Ups!",
            html: "<strong>Algo salió mal<strong><br><br><small>Por favor póngase en contacto con el administrador</small>",
            icon: "error",
            buttonsStyling: false,
            showConfirmButton: false,
            timer: 2000,
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };
    const checkTerms = (val) => {
      checks.value = {
        minus: /[a-z]/.test(val.target.value),
        mayus: /[A-Z]/.test(val.target.value),
        special: /[_.@$!%*?&#]/.test(val.target.value),
        number: /\d/.test(val.target.value),
        min: val.target.value.length >= characters.value,
      };
      console.log(val.target.value, checks.value);
    };
    onMounted(() => {
      if (route.params.pwd) {
        provisional.value = route.params.pwd;
      }
    });

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      checkTerms,
      checks,
      characters,
      toggleType,
      type1,
      type2,
      type3,
      provisional,
    };
  },
});
